/* html, body {
  font-size: 14px;
} */

[class*="sidebar-dark-"] .sidebar a {
  color: #e7e7e7;
}

.sidebar-collapse .hide-when-collapse {
  display: none;
}

[class*="sidebar-dark-"] .nav-treeview>.nav-item>.nav-link {
  color: #e7e7e7;
}

.brand-link .brand-image {
  float: none;
}

.table>:not(caption)>*>* {
  font-size: 14px;
  padding: 0.75rem;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-size: 14px;
  font-weight: 500;
}

.react-responsive-modal-modal {
  margin: 70px 1.2rem 1.2rem 1.2rem;
}

.main-header {
  z-index: 995;
}

.card-primary.card-outline-tabs>.card-header a:hover {
  border-top: 3px solid #F46C03;
}

.card-primary.card-outline-tabs>.card-header a.active {
  border-top: 3px solid #F46C03;
}

a:hover {
  color: #F46C03;
}

a {
  color: #F46C03;
  text-decoration: underline;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #F46C03;
  color: #fff;
}

.col-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.col-filters>div {
  min-width: 100px;
  width: 150px;
}

.pagination-limit {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

.react-responsive-modal-modal{
  width: -webkit-fill-available;

}
@media (max-width: 551px) {
  .pagination-limit {
    flex-direction: column;

  }

}